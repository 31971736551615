import React, {useState, useEffect} from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Categorie from './components/categorie/categorie';
import Categorie2 from './components/categorie/categorie2';
import Categorie3 from './components/categorie/categorie3';
import Categorie4 from './components/categorie/categorie4';
import Library from './components/Libary/Libary';
import Omar from './components/mawsouaa/akwal/omar';
import Aboubakr from './components/mawsouaa/akwal/aboubakr';
import Ibrahim from './components/mawsouaa/akwal/ibrahim';
import Malik from './components/mawsouaa/akwal/malik';
import Ihklas from './components/mawsouaa/tazkiya_w_adhkar/ikhlas';
import Rajaa from './components/mawsouaa/tazkiya_w_adhkar/rajaa';
import Khawf from './components/mawsouaa/tazkiya_w_adhkar/khawf';
import Salat from './components/mawsouaa/e3raf_dinek/salat';
import Sawm from './components/mawsouaa/e3raf_dinek/sawm';
import Zakat from './components/mawsouaa/e3raf_dinek/zakat';
import Tawhid from './components/mawsouaa/e3raf_dinek/tawhid';
import Fajr from './components/mawsouaa/ad3iya/douaafajr';
import Athkarmasaa from './components/mawsouaa/ad3iya/athkarmasaa';
import Athkarsabah from  './components/mawsouaa/ad3iya/athkatsabah';   
import Joumouaa from  './components/mawsouaa/ad3iya/douaajoumouaa';   
import Salatnabi from  './components/mawsouaa/ad3iya/salatnabi';   
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2900);
  }, []);
  

  return (
    <div >
      {loading ? (
        <div className="loader-container">
          <PuffLoader  color={'#FFD358'} loading={loading} size={140}  speedMultiplier={2}/>
        </div>
      ) : (
        <>
          <Router>
      <Routes>
        <Route path="/" element={<Library/>} />
        <Route path="/الموسوعة" element={<Library/>} />
        <Route path="/أدعية-و-أذكار" element={<Categorie/>} />
        <Route path="/أقوال-العظماء" element={<Categorie4/>} />
        <Route path='/التزكية-و-الأخلاق' element={<Categorie3/>} />
        <Route path="/إعرف-دينك" element={<Categorie2/>} />
        <Route path="/عمر-الخطاب" element={<Omar/>} />
        <Route path="/أبو-بكر-الصديق" element={<Aboubakr/>} />
        <Route path='/إبراهيم-الفقيه' element={<Ibrahim/>} />
        <Route path='/مالك-بن-نبي'  element={<Malik/>} />
        <Route path="/الإخلاص" element={<Ihklas/>} />
        <Route path='/الخوف-والخشية' element={<Khawf/>} />
        <Route path="/الرجاء" element={<Rajaa/>} />
        <Route path='/الصلاة' element={<Salat/>} />
        <Route path='/الصوم' element={<Sawm/>} />
        <Route path='/الزكاة' element={<Zakat/>} />
        <Route path='/التوحيد' element={<Tawhid/>} />
        <Route path='/صلاة-على-النبي' element={<Salatnabi/>} />
        <Route path='/أذكار-الصباح' element={<Athkarsabah/>} /> 
        <Route path='/أذكار-المساء' element={<Athkarmasaa/>} /> 
        <Route path='/أذكار-يوم-الجمعة' element={<Joumouaa/>} />
        <Route path='/صلاة-الفجر' element={<Fajr/>} />





        
      </Routes>
    </Router>
        </>
      )}
  
    </div>

  );
}

export default App;
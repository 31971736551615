import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { akwal2, akwal3, akwal5, akwal7, salat, sawm, zakat, tawhid, ikhlas, khichya, rajaa, AthkarSabeh, AthkarMasaa, AthkarJoumouaa, SalatAlaNabi, DOUAAFAJR } from '../../dataLibrary'; // Import des éléments du portfolio
import 'font-awesome/css/font-awesome.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './suggestion.css';

// Function to shuffle array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function Suggestion() {
  const [shuffledItems, setShuffledItems] = useState([]);

  useEffect(() => {
    // Shuffle the portfolio items and limit to the max number
    const shuffled = shuffleArray([
      ...akwal2, ...akwal3, ...akwal5, ...akwal7, ...DOUAAFAJR, ...salat, ...sawm, ...zakat, ...tawhid, ...ikhlas,
      ...AthkarJoumouaa, ...AthkarMasaa, ...AthkarSabeh, ...SalatAlaNabi, ...khichya, ...rajaa
    ]).slice(0, 6);
    setShuffledItems(shuffled);
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const settings2 = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='Suggestion'>
      <p className='Suggestion-title'>إكتشف أيضا</p>
      <Slider {...settings2}>
        {shuffledItems.map((item) => (
          <Link to={item.link} key={item.id} onClick={handleClick}>
            <div className="port-item1">
              <img src={item.src} alt={item.title} />
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
}

export default Suggestion;

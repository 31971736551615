import React, { useState } from 'react';
import '../mawsouaa.css';
import {DOUAAFAJR} from '../../../dataLibrary'; // Chemin relatif correct
import Portfolio from '../../blog/portfolio'; // Chemin relatif correct
import { PiKeyReturnBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import Header from '../../header/header'; // Chemin relatif correct
import Footer from '../../footer/footer'; // Chemin relatif correct

function Categorie() {
  const [currentPage, setCurrentPage] = useState(1);
  
  return (
    <>
      <Header />
      <div className="section-title">
      <Link to='/أدعية-و-أذكار'>
      <PiKeyReturnBold className='iconCategorie'/>
        </Link>
        دعاء صلاة الفجر
          </div>
      <div className='hello'>
        <Portfolio data={DOUAAFAJR} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      <Footer />
    </>
  );
}

export default Categorie;

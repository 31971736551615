import React, { useState } from 'react';
import './categorie.css';
import Header from '../header/header';
import Footer from '../footer/footer';
import { datacategorie} from '../../dataLibrary.jsx';
import { PiKeyReturnBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import Suggestion from '../Suggestions/suggestion.jsx';

const CircleButton = ({ text, link }) => (
  <Link to={link} className="circle-button-container">
    <div className="circle-button">
      {text}
    </div>
  </Link>
);

const Categorie = () => {

  return (
    <>
      <Header />
      <div className="section-title">
        <Link to="/الموسوعة">
          <PiKeyReturnBold className='iconCategorie'/>
        </Link>
        أدعية و أذكار
      </div>
      <div className="container">
        <div className="button-container">
          {datacategorie.map((button) => (
            <CircleButton
              key={button.id}
              text={button.text}
              link={button.link}
            />
          ))}
        </div>
      </div>
      <Suggestion/>
      
      <Footer />
    </>
  );
};
export default Categorie;

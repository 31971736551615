import React from 'react';
import './footer.css';
import logo from '../../assets/logo25.png';
import { Link } from 'react-router-dom';
export default function Footer(props) {
  return (
    <footer>
      <div className="grid-container">
      <div className="grid-item">
          <h3>اشترك معنا في الإخبارية</h3>
          <div className='INSCRIT'>
            <input type="email" placeholder="بريدك الإلكتروني ..." />
            <button>اشترك معنا</button>
          </div>
        </div>
      
        <div className="grid-item">
          <h3>روابط الصفحة</h3>
          <ul className="LISTE">
            <li>
              <Link to='/الموسوعة'>الرئيسية</Link>
            </li>
            <li>
             <Link to='/الموسوعة'>الموسوعة</Link> 
            </li>
            <li>
              <Link to='/الموسوعة'>الأكادمية</Link>
            </li>
            <li>
              <Link to='/الموسوعة'>المدونات</Link>
            </li>
            <li>
              <Link to='/الموسوعة'>اتصل بنا</Link>
            </li>
          </ul>
        </div>
        <div className="grid-item">
          <h3> أكادمية جواهر القلوب</h3>
          <img src={logo} alt="logo" className="logofooter" />
        </div>
        <div className="grid-item social" >
        <hr className="separator" />
         <a href="tel:+21624466104">
          <div>
            <p>
              <i className="fas fa-phone-alt"></i>
            </p>
            <p className='SOCIALTEXT'>24-466-104 (216+)</p>
          </div>
          </a>

          <a href="mailto:academiejawaher@gmail.com">
          <div>
            <p>
              <i className="fas fa-envelope"></i>
            </p>
            <p className='SOCIALTEXT'>academiejawaher@gmail.com</p>
          </div>
          </a>

          <a href="https://maps.app.goo.gl/555ezURtnqjkQxXF8" target="_blank" >
          <div>
            <p>
            <i className="fas fa-map-marker-alt"></i>            
            </p>
            <p className='SOCIALTEXT'>جمال، المنستير، تونس</p>
          </div>
          </a>
          <a href='https://www.facebook.com/groups/academiejawaher/'>
          <div>
            <p>
              <i className="fab fa-facebook"></i>
            </p>
            <p className='SOCIALTEXT'>أكادمية جواهر القلوب</p>
          </div>
          </a>

        </div>
      </div>
      <div className="footer2">
        <p>
          &copy; {new Date().getFullYear()}{' '}
          <span>أكادمية جواهر القلوب</span> .كل الحقوق محفوظة .
        </p>
      </div>
    </footer>
  );
}

import React, { useState } from 'react';
import '../mawsouaa.css'; // Assurez-vous que le chemin est correct
import { akwal2 } from '../../../dataLibrary'; // Chemin relatif correct
import Portfolio from '../../blog/portfolio'; // Chemin relatif correct
import { PiKeyReturnBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import Header from '../../header/header'; // Chemin relatif correct
import Footer from '../../footer/footer'; // Chemin relatif correct


function Categorie() {
  const [currentPage, setCurrentPage] = useState(1);

  
  
  return (
    <>
      <Header/>
      <div className="section-title">
      <Link to="/أقوال-العظماء">
      <PiKeyReturnBold className='iconCategorie'/>
        </Link>
        عمرالخطاب
      </div>
      <div className='hello'>
        <Portfolio data={akwal2} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      <Footer />
    </>
  );
}

export default Categorie;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Libary.css';
import { dataLibrary } from '../../dataLibrary.jsx';
import { FaPlus } from "react-icons/fa";
import Header from '../header/header';
import Footer from '../footer/footer';
import Suggestion from '../Suggestions/suggestion';

function Library() {
  const [divs, setDivs] = useState(dataLibrary);

 

  return (
    <>
    <Header />
    <div className="Libary">
          <div className="containerLibary">
            {divs.map((div) => (
              <Link to={div.link} key={div.id}>
                <div className="boxLibary">
                  {div.content}
                </div>
              </Link>
            ))}
            
           
           
          </div>
        </div>
        <Suggestion />
        <Footer />
    </>
   
  );
}

export default Library;

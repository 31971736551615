import { useState, useEffect } from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faUser,
  faMagnifyingGlass,
  faPhone,
  faEnvelope,
  faLocationDot,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo25.png";
import { Link } from "react-router-dom";

import {
  dataLibrary,
  datacategorie,
  datacategorie2,
  datacategorie3,
  datacategorie4
} from "../../dataLibrary";

export default function Example(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      performSearch(searchTerm.trim());
    }
  };


  const closeSearch = () => {
    setIsSearchVisible(false);
  };

  const allData = [
    ...dataLibrary,
    ...datacategorie,
    ...datacategorie2,
    ...datacategorie3,
    ...datacategorie4
  ];

  const searchLinkByText = (text) => {
    text = text.trim().toLowerCase(); // Convertir en minuscules et supprimer les espaces au début et à la fin

    const result = allData.find(
      (item) =>
        (item.content && item.content.toLowerCase().includes(text)) ||
        (item.text && item.text.toLowerCase().includes(text))
    );

    return result ? result.link : "Link not found";
  };

  const performSearch = (text) => {
    const link = searchLinkByText(text);
    if (link !== "Link not found") {
      navigate(link);
    } else {
      alert("Link not found");
    }
  };

  return (
    <div>
      <nav className="nav1">
        <div className="nav12">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="search1" />
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="search"
            onClick={toggleSearch}
          />
          <input
            type="text"
            placeholder="ابحث هنا ...."
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
        </div>

        {isSearchVisible && (
          <div className="search-bar">
            <FontAwesomeIcon
              icon={faTimes}
              className="closesearch"
              onClick={closeSearch}
            />
            <input
              type="text"
              placeholder="ابحث هنا ...."
              className="search-input"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        )}

        <div className="nav11">
          <div className="nav13">
            <a href="https://maps.app.goo.gl/555ezURtnqjkQxXF8" target="_blank">
              <FontAwesomeIcon icon={faLocationDot} className="icon" />
              &nbsp;
              <span className="text">
                {" "}
                الموقع
                <br />
                جمال، المنستير، تونس{" "}
              </span>
            </a>
          </div>
          <hr className="vertical-line" />
          <div className="nav13">
            <a href="mailto:academiejawaher@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              &nbsp;
              <span className="text">
                {" "}
                البريد الإلكتروني
                <br />
                academiejawaher@gmail.com
              </span>
            </a>
          </div>
          <hr className="vertical-line" />
          <div className="nav13">
            <a href="tel:+21624466104">
              <FontAwesomeIcon icon={faPhone} className="icon" />
              &nbsp;
              <span className="text">
                {" "}
                رقم الهاتف
                <br />
                24-466-104 (216+)
              </span>
            </a>
          </div>
        </div>
      </nav>
      <nav
        className="flex items-center flex-wrap bg-white p-4 w-full z-10 top-15 shadow-lg h-25"
        style={{ direction: "rtl" }}
      >
        <div className="flex items-center justify-between w-full lg:w-auto">
          <div className="flex items-center text-white">
            <a className="titre flex items-center">
              <img src={logo} alt="logo" className="logo" />
              <span className="TITLE"> أكادمية جواهر القلوب</span>
            </a>
          </div>
          <div className="block lg:hidden">
            <button
              className="flex items-center px-2 py-2 border rounded text-black border-black "
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>
        <div
          className={`w-full flex-grow lg:flex lg:items-center lg:w-auto ${
            isNavOpen ? "nav-responsive" : "hidden"
          }`}
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <Link
                to="/الموسوعة"
                className="inline-block py-2 px-4 no-underline hover:text-yellow-400 hover:text-underline py-2 px-4"
              >
                الرئيسية
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/الموسوعة"
                className="inline-block no-underline hover:text-yellow-400 hover:text-underline py-2 px-4"
              >
                الموسوعة
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/الموسوعة"
                className="inline-block no-underline hover:text-yellow-400 hover:text-underline py-2 px-4"
              >
                الأكادمية
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/الموسوعة"
                className="inline-block no-underline hover:text-yellow-400 hover:text-underline py-2 px-4"
              >
                المدونات
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/الموسوعة"
                className="inline-block no-underline hover:text-yellow-400 hover:text-underline py-2 px-4"
              >
                اتصل بنا
              </Link>
            </li>
            <li className="mr-3">
              <Link
                to="/الموسوعة"
                className="inline-block no-underline hover:text-yellow-400 hover:text-underline py-2 px-4"
              >
                <FontAwesomeIcon icon={faUser} />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export const dataLibrary = [
  { id: 1, content: 'أدعية و أذكار', link: '/أدعية-و-أذكار' },
  { id: 2, content: 'أقوال العظماء', link:  '/أقوال-العظماء' },
  { id: 3, content: 'إعرف دينك', link: '/إعرف-دينك' },
  { id: 4, content: 'التزكية و الأخلاق', link: '/التزكية-و-الأخلاق' },
];

export const datacategorie = [
  { id: 1,text: 'أذكار الصباح',link:'/أذكار-الصباح' },
  { id: 2, text: 'أذكار المساء' ,link:'/أذكار-المساء' },
  { id: 3,text: 'أذكار يوم الجمعة' ,link:'/أذكار-يوم-الجمعة' },
  { id: 4, text: 'دعاء صلاة الفجر',link:'/صلاة-الفجر' },
  { id: 5, text: 'الصلاة على النبي',link:'/صلاة-على-النبي' },

];

export const datacategorie2 = [
  { id: 1,text: 'الصوم',link:'/الصوم' },
  { id: 2, text:'الصلاة' ,link:'/الصلاة' },
  { id: 3,text: 'الزكاة',link:'/الزكاة' },
  { id: 4, text: 'التوحيد',link:'/التوحيد' },
];

export const datacategorie3 = [
  { id: 1,text: 'الخوف والخشية',link:'/الخوف-والخشية' },
  { id: 2, text:'الإخلاص' ,link:'/الإخلاص' },
  { id: 3,text: 'الرجاء',link:'/الرجاء' },
];

export const datacategorie4 = [
  { id: 1,text:' عمرالخطاب',link:'/عمر-الخطاب'},
  { id: 2,text: 'أبو بكر الصديق',link:'/أبو-بكر-الصديق' },
  { id: 3,text: 'إبراهيم الفقيه',link:'/إبراهيم-الفقيه' },
  { id: 4,text: 'مالك بن نبي',link:'/مالك-بن-نبي' },
  ];




export const akwal2 = [
  { id : 1, src: 'img/omar_khatab/85.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258485-omarkhatab085.png',title: 'عمرالخطاب',intro:'أَبُو حَفْصٍ عُمَرُ بْنُ الْخَطَّابِ الْعَدَوِيُّ الْقُرَشِيُّ، المُلقب بالفاروق، هو ثاني الخلفاء الراشدين ومن كبار أصحاب الرسول محمد، وأحد أشهر الأشخاص والقادة في التاريخ الإسلامي ومن أكثرهم تأثيرًا ونفوذًا. هو أحد العشرة المبشرين بالجنة، ومن علماء الصحابة وزهّادهم.', description: ['مقدسة','مجهدة','فراغ','شغل'] ,link:'/عمر-الخطاب'},
  { id : 2 ,src: 'img/omar_khatab/76.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258419-omarkhatab076.png',title: 'عمرالخطاب',intro:'أَبُو حَفْصٍ عُمَرُ بْنُ الْخَطَّابِ الْعَدَوِيُّ الْقُرَشِيُّ، المُلقب بالفاروق، هو ثاني الخلفاء الراشدين ومن كبار أصحاب الرسول محمد، وأحد أشهر الأشخاص والقادة في التاريخ الإسلامي ومن أكثرهم تأثيرًا ونفوذًا. هو أحد العشرة المبشرين بالجنة، ومن علماء الصحابة وزهّادهم.',description: ['قتل','صدق'] , link:'/عمر-الخطاب'},
  { id : 3,src: 'img/omar_khatab/148.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258425-148.jpg',title: 'عمرالخطاب', intro:'أَبُو حَفْصٍ عُمَرُ بْنُ الْخَطَّابِ الْعَدَوِيُّ الْقُرَشِيُّ، المُلقب بالفاروق، هو ثاني الخلفاء الراشدين ومن كبار أصحاب الرسول محمد، وأحد أشهر الأشخاص والقادة في التاريخ الإسلامي ومن أكثرهم تأثيرًا ونفوذًا. هو أحد العشرة المبشرين بالجنة، ومن علماء الصحابة وزهّادهم.',description: ['ذل','عزة','إسلام'] , link:'/عمر-الخطاب'},
  { id : 4 ,src: 'img/omar_khatab/228.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258499-omarkhatab228.png',title: 'عمرالخطاب',intro:'أَبُو حَفْصٍ عُمَرُ بْنُ الْخَطَّابِ الْعَدَوِيُّ الْقُرَشِيُّ، المُلقب بالفاروق، هو ثاني الخلفاء الراشدين ومن كبار أصحاب الرسول محمد، وأحد أشهر الأشخاص والقادة في التاريخ الإسلامي ومن أكثرهم تأثيرًا ونفوذًا. هو أحد العشرة المبشرين بالجنة، ومن علماء الصحابة وزهّادهم.', description: ['فقر','قتل'] , link:'/عمر-الخطاب'},
  { id : 5, src: 'img/omar_khatab/180.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258485-omarkhatab180.png',title: 'عمرالخطاب',intro:'أَبُو حَفْصٍ عُمَرُ بْنُ الْخَطَّابِ الْعَدَوِيُّ الْقُرَشِيُّ، المُلقب بالفاروق، هو ثاني الخلفاء الراشدين ومن كبار أصحاب الرسول محمد، وأحد أشهر الأشخاص والقادة في التاريخ الإسلامي ومن أكثرهم تأثيرًا ونفوذًا. هو أحد العشرة المبشرين بالجنة، ومن علماء الصحابة وزهّادهم.', description: ['زهد'] , link:'/عمر-الخطاب'},

];


export const akwal3 = [
  { id : 1, src: 'img/abou_bakr/701.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257813-aboubaker701.png',title: 'أبو بكر الصديق', intro: 'الصحابي الجليل أبو بكر الصديق رفيق النبي في هجرته واحد من أهم الشخصيات الإسلامية، له أوليات، منها أنه أول رجل حر أسلم، وأول الخلفاء الراشدين، ولد بمكة وكان رفيق النبي -صلى الله عليه وسلم- في هجرته إلى المدينة المنورة', description: ['حياة','موت'],link:'/أبو-بكر-الصديق' },
  { id : 2, src: 'img/abou_bakr/1086.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257813-aboubaker1086.png',title: 'أبو بكر الصديق', intro: 'الصحابي الجليل أبو بكر الصديق رفيق النبي في هجرته واحد من أهم الشخصيات الإسلامية، له أوليات، منها أنه أول رجل حر أسلم، وأول الخلفاء الراشدين، ولد بمكة وكان رفيق النبي -صلى الله عليه وسلم- في هجرته إلى المدينة المنورة' ,description: ['أكل','شجرة'],link:'/أبو-بكر-الصديق' },
  { id : 3, src: 'img/abou_bakr/1103.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257961-aboubaker1103.png',title: 'أبو بكر الصديق', intro: 'الصحابي الجليل أبو بكر الصديق رفيق النبي في هجرته واحد من أهم الشخصيات الإسلامية، له أوليات، منها أنه أول رجل حر أسلم، وأول الخلفاء الراشدين، ولد بمكة وكان رفيق النبي -صلى الله عليه وسلم- في هجرته إلى المدينة المنورة',description:  ['عزاء','مصيبة'],link:'/أبو-بكر-الصديق' },
  { id : 4, src: 'img/abou_bakr/1136.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257962-aboubaker1136.png',title: 'أبو بكر الصديق', intro: 'الصحابي الجليل أبو بكر الصديق رفيق النبي في هجرته واحد من أهم الشخصيات الإسلامية، له أوليات، منها أنه أول رجل حر أسلم، وأول الخلفاء الراشدين، ولد بمكة وكان رفيق النبي -صلى الله عليه وسلم- في هجرته إلى المدينة المنورة',description: ['يوم','أهل بيت','رزق','نفقة','بغض'],link:'/أبو-بكر-الصديق' },
  { id : 5, src: 'img/abou_bakr/1177.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258032-aboubaker1177.png',title: 'أبو بكر الصديق', intro: 'الصحابي الجليل أبو بكر الصديق رفيق النبي في هجرته واحد من أهم الشخصيات الإسلامية، له أوليات، منها أنه أول رجل حر أسلم، وأول الخلفاء الراشدين، ولد بمكة وكان رفيق النبي -صلى الله عليه وسلم- في هجرته إلى المدينة المنورة',description: ['ذنب','قلب','قسوة','دمع'], link:'/أبو-بكر-الصديق' },
  { id : 6, src: 'img/abou_bakr/1162.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258032-aboubaker1162.png',title: 'أبو بكر الصديق', intro: 'الصحابي الجليل أبو بكر الصديق رفيق النبي في هجرته واحد من أهم الشخصيات الإسلامية، له أوليات، منها أنه أول رجل حر أسلم، وأول الخلفاء الراشدين، ولد بمكة وكان رفيق النبي -صلى الله عليه وسلم- في هجرته إلى المدينة المنورة',description:  ['رعاية الحق','نائبة','وفاء','سرور'],link:'/أبو-بكر-الصديق' },

];




export const akwal5 = [
  { id : 1,src: 'img/ibrahim_fakih/1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257601-1.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['هدف','فكرة'],link:'/إبراهيم-الفقيه' },
  { id : 2,src: 'img/ibrahim_fakih/6.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257602-6.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['سلوك سلبي','شعور','تحد','نتيجة إيجابية'],link:'/إبراهيم-الفقيه' },
  { id : 3,src: 'img/ibrahim_fakih/7.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257639-7.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['مبادئ الإتصال','الإنصات','القوة الذاتية','الصمت'],link:'/إبراهيم-الفقيه' },
  { id : 4,src: 'img/ibrahim_fakih/13.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257642-13.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['التغيير','الإدراك'] ,link:'/إبراهيم-الفقيه' },
  { id : 5,src: 'img/ibrahim_fakih/15.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257679-15.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['الناس','ضياع','هدف'] ,link:'/إبراهيم-الفقيه' },
  { id : 6,src: 'img/ibrahim_fakih/18.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257726-18.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['مهارة','تخيل','هدف','معلومة','تنمية بشرية','عقل'] ,link:'/إبراهيم-الفقيه' },
  { id : 7,src: 'img/ibrahim_fakih/20.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721257681-20.png', title: 'إبراهيم الفقيه',intro:'إبراهيم محمد السيد الفقي ، خبير التنمية البشرية والبرمجة اللغوية العصبية ورئيس مجلس إدارة المركز الكندي للتنمية البشرية، ومُؤسس ورئيس مجلس إدارة مجموعة شركات إبراهيم الفقي العالمية التي تتكون من (المركز الكندي لقوة الطاقة البشرية، المركز الكندي للتنويم بالإحياء، المركز الكندي للتنمية البشرية، المركز الكندي البرمجة اللغوية والعصبية). له مؤلفات تُرجمت إلى عدة لغات. درب أكثر من 600 ألف شخص في محاضراته حول العالم ', description: ['حياة','صحة','راحة','عمل'] ,link:'/إبراهيم-الفقيه' },
];

export const akwal7 = [
  { id : 1,src: 'img/malek_ben_nabi/80.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258148-malekbenabi-0080-logo25-copie-51.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: ['عمل سياسي','الدولة','عمل','السياسة','وسائل','إحباط','دستور'],link:'/مالك-بن-نبي' },
  { id : 2,src: 'img/malek_ben_nabi/88.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258147-malekbenabi-0088-logo25-copie-43.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: ['ساعة الصفر','ساعة','بغث','تجدد'],link:'/مالك-بن-نبي' },
  { id : 3,src: 'img/malek_ben_nabi/89.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258206-malekbenabi-0089-logo25-copie-42.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: ['إستعمار','العالم','سلم','عصر'],link:'/مالك-بن-نبي' },
  { id : 4,src: 'img/malek_ben_nabi/130.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258206-malekbenabi-0130-logo25-copie.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: ['إستعمار','أذى','دين','إسلام','سلطة','عربية'],link:'/مالك-بن-نبي' },
  { id : 5,src: 'img/malek_ben_nabi/10.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258242-malekbenabi-0010-lien-site-copie-10.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: [],link:'/مالك-بن-نبي' },
  { id : 6,src: 'img/malek_ben_nabi/45.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258242-malekbenabi-0045-logo25-copie-86.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: [],link:'/مالك-بن-نبي' },
  { id : 7,src: 'img/malek_ben_nabi/46.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258295-malekbenabi-0046-logo25-copie-85.jpg',title: 'مالك بن نبي',intro:'"مفكر جزائري، أحدث نقلة نوعية في الفكر الإسلامي الحديث، وأسس مفاهيم ومصطلحات فكرية وفلسفية غير مسبوقة. لقب بألقاب عديدة منها "فيلسوف العصر" و"فقيه الحضارة" و"منظر النهضة الإسلامية', description: [],link :'/مالك-بن-نبي' },
];


//i3raf dinek
export const tawhid = [
  { id : 1,src: 'img/tawhid/4-1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258650-4-1.jpg', title: 'التوحيد',intro:'', description: ['بلوغ', 'عقل','مكلف'],link: '/التوحيد' },
  { id : 2,src: 'img/tawhid/4-2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258650-4-2.jpg', title: 'التوحيد',intro:'', description: ['حكم شرعي','فرض','سنة','مكروه','حرام'],link: '/التوحيد' },
  { id : 3,src: 'img/tawhid/4-3.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258710-4-3.jpg', title: 'التوحيد',intro:'', description: ['مكلف','أمر','واجب','ثواب','عقاب'],link: '/التوحيد' },
  { id : 4,src: 'img/tawhid/4-4.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258709-4-4.jpg', title  :'التوحيد',intro:'', description: ['سنة','مستحب','ثواب','عقاب'],link: '/التوحيد' },
  { id : 5,src: 'img/tawhid/4-5.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258758-4-5.jpg', title: 'التوحيد',intro:'', description: ['مكروه','مستحب','ثواب','عقاب'],link: '/التوحيد' },
  { id : 6,src: 'img/tawhid/4-6.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258758-4-6.jpg', title: 'التوحيد ',intro:'', description: ['حرام','واجب','ثواب','عقاب'],link: '/التوحيد' },
  { id : 7,src: 'img/tawhid/4-7.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258781-4-7.jpg', title: 'التوحيد ',intro:'', description: ['مباح','ثواب','عقاب'],link: '/التوحيد' },

];
export const sawm = [
  { id : 1,src: 'img/sawm/13-4.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258932-13-4.jpg', title: 'الصوم',intro:'', description: ['مكروهات الصوم'],link: '/الصوم' },
  { id : 2,src: 'img/sawm/13-3.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258933-13-3.jpg', title: 'الصوم',intro:'', description: ['مستحبات الصوم','الصوم','سحور'],link: '/الصوم' },
  { id : 3,src: 'img/sawm/13-2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258987-13-2.jpg', title: 'الصوم',intro:'', description: ['فرائض الصوم'],link: '/الصوم' },
  { id : 4,src: 'img/sawm/13-1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721258988-13-1.jpg', title  :'الصوم',intro:'', description: ['شهر رمضان','الصوم'],link: '/الصوم' },
  { id : 5,src: 'img/sawm/13-8.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721259091-13-8.jpg', title: 'الصوم',intro:'', description: ['القضاء','الصوم'],link: '/الصوم' },
  { id : 6,src: 'img/sawm/13-7.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721259038-13-7.jpg', title: 'الصوم ',intro:'', description: ['الكفارة','الصوم'],link: '/الصوم' },
  { id : 7,src: 'img/sawm/13-6.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721259138-13-6.jpg', title: 'الصوم ',intro:'', description: ['الكفارة','الصوم'],link: '/الصوم' },
  { id : 8,src: 'img/sawm/13-5.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721259038-13-5.jpg', title: 'الصوم ',intro:'', description: ['مفطرات الصوم','الصوم'],link: '/الصوم' },
  { id : 9,src: 'img/sawm/23.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260370-23.jpg', title: 'الصوم',intro:'', description: ['يوم الخميس','صيام','يوم الإثنين'],link: '/الصوم' },
  { id : 10,src: 'img/sawm/21.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721259157-21.jpg', title: 'الصوم',intro:'', description: ['المؤمنون','تقوى', 'صيام'],link: '/الصوم' },
  { id : 11,src: 'img/sawm/13-10.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260366-13-10.jpg', title: 'الصوم',intro:'', description: ['القضاء'],link: '/الصوم' },
  { id : 12,src: 'img/sawm/13-9.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721259089-13-9.jpg', title: 'الصوم',intro:'', description: ['قضاء','كفارة'],link: '/الصوم' },

];


export const salat = [
  { id : 1,src: 'img/salat/24.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260681-24.jpg', title: 'الصلاة',intro:'', description: ['الصلاة','عماد الدين'],link: '/الصلاة' },
  { id : 2,src: 'img/salat/11-4.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260665-11-4.jpg', title: 'الصلاة',intro:'', description: ['الصلوات المفروضة','الصبح','الظهر','العصر','المغرب','العشاء'],link: '/الصلاة' },
  { id : 3,src: 'img/salat/11-3.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260627-11-3.jpg', title: 'الصلاة',intro:'', description: ['القنوت','مستحب','الصلاة','دعاء القنوت'],link: '/الصلاة' },
  { id : 4,src: 'img/salat/11-2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260628-11-2.jpg', title: 'الصلاة',intro:'', description: ['الصلاة','التشهد'],link: '/الصلاة' },
  { id : 5,src: 'img/salat/249.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260731-249.jpg', title: 'الصلاة',intro:'', description: ['الصلاة','الراحة','البركة','الحياة','الهجرة'],link: '/الصلاة' },
  { id : 6,src: 'img/salat/247.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260718-247.jpg', title: 'الصلاة',intro:'', description: ['الصلاة','حب','فرض'],link: '/الصلاة'},
  { id : 7,src: 'img/salat/25.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260682-25.jpg', title: 'الصلاة',intro:'', description: ['الصلاة'],link: '/الصلاة' },
  { id : 8,src: 'img/salat/10-2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260562-10-2.jpg', title: 'الصلاة',intro:'', description: ['مكروهات الصلاة','البسملة'],link: '/الصلاة' },
  { id : 9,src: 'img/salat/8-3.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260560-8-3.jpg', title: 'الصلاة',intro:'', description: ['مسلم','بلوغ','عقل','ولي الصبي'],link: '/الصلاة' },
  { id : 10,src: 'img/salat/8-2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260465-8-2.jpg', title: 'الصلاة',intro:'', description: ['شروط الصحة','الطهارة','ستر العورة','إستقبال القبلة'],link: '/الصلاة' },
  { id : 11,src: 'img/salat/8-1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260464-8-1.jpg', title: 'الصلاة',intro:'', description: ['الدعاء','ركوع','تكبير','سجود','تسليم','الصلاة'],link: '/الصلاة' },
];

export const zakat = [
  { id : 1,src: 'img/zakat/12-1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261099-12-1.jpg', title: 'الزكاة',intro:'', description: ['شريعة','مال','الصدقة','الزكاة'],link: '/الزكاة' },
  { id : 2,src: 'img/zakat/12-2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261099-12-2.jpg', title: 'الزكاة',intro:'', description: ['عيد الفطر','عقل','بلوغ','زكاة الفطر'],link: '/الزكاة' },
  { id : 3,src: 'img/zakat/909.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721260907-909.jpg', title: 'الزكاة',intro:'', description: ['التعفف','الإستغناء','صدقة','اليد السفلى','اليد العليا'],link: '/الزكاة' },
];

//tazkia et akhlak
export const ikhlas = [
  { id : 1,src: 'img/ikhlas/7.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261296-7.jpg', title: 'الإخلاص',intro:'', description: ['قلب','عمل','الله','صورة','جسم','نظرة'], link: '/الإخلاص' },
  { id : 2,src:'img/ikhlas/1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261244-1.jpg', title: 'الإخلاص',intro:'', description: [], link: '/الإخلاص' },
  { id : 3,src:'img/ikhlas/3.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261238-3.jpg', title: 'الإخلاص',intro:'', description: [], link: '/الإخلاص' },
  { id : 4,src:'img/ikhlas/5.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261300-5.jpg', title: 'الإخلاص',intro:'', description: [], link: '/الإخلاص' },
];

export const khichya = [
  { id : 1,src: 'img/kichya/725.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261513-725.jpg', title: 'الخشية و الخوف',intro:'', description: ['خبر','عمل','شهادة'],link:'/الخوف-والخشية' },
  { id : 2,src: 'img/Kichya/715.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261513-715.jpg', title: 'الخشية و الخوف',intro:'', description: ['عمر','علم','مال','نفقة'],link:'/الخوف-والخشية' },
  { id : 3,src: 'img/Kichya/711.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261359-711.jpg', title: 'الخشية و الخوف',intro:'', description: ['ضحك','بكاء','صحابة','وجه'],link:'/الخوف-والخشية' },
  { id : 4,src: 'img/Kichya/813.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261423-813.jpg', title: 'الخشية و الخوف',intro:'', description: ['جنة','نار'],link:'/الخوف-والخشية' },
  { id : 5,src: 'img/Kichya/817.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261441-817.jpg', title: 'الخشية و الخوف',intro:'', description: ['رجل','الحب في الله','منصب','إمرأة','صدقة','ذكر','ظل','إمام عادل','شباب','مسجد'],link:'/الخوف-والخشية' },
  { id : 6,src: 'img/Kichya/713.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261359-713.jpg', title: 'الخشية و الخوف',intro:'', description: [],link:'/الخوف-والخشية' },
  { id : 7,src: 'img/Kichya/829.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261571-829.jpg', title: 'الخشية و الخوف',intro:'', description: ['أبو بكر الصديق','صلاة','عائشة رضي الله عنها','بكاء'],link:'/الخوف-والخشية' },
  { id : 8,src: 'img/Kichya/831.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261571-831.jpg', title: 'الخشية و الخوف',intro:'', description: [],link:'/الخوف-والخشية' },

];

export const rajaa = [
  { id : 1,src: 'img/rajaa/749.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261708-749.jpg', title: 'الرجاء',intro:'', description: ['رحمة','أرض','خلائق','الدابة','حافر الدابة','ولد','خشية'], link: '/الرجاء'},
  { id : 2,src: 'img/rajaa/773.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261637-773.jpg', title: 'الرجاء',intro:'', description: ['بسط اليد','الله','توبة','مسيء','نهار','ليل','شمس','طلوع الشمس'], link: '/الرجاء'},
  { id : 3,src: 'img/rajaa/783.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261738-783.jpg', title: 'الرجاء',intro:'', description: ['عبد','ذكر','الله','توبة','ضالة','فلاة','شبر','ذراع','هرولة','مسيء'],link: '/الرجاء'},
  { id : 4,src: 'img/rajaa/801.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261792-801.jpg', title: 'الرجاء',intro:'', description: ['الله','عقوبة','جنة','كافر','رحمة','جنة'], link: '/الرجاء'},  
  { id : 5,src: 'img/rajaa/215.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261662-215.jpg', title: 'الرجاء',intro:'', description: [], link: '/الرجاء'},
  { id : 6,src: 'img/rajaa/745.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261706-745.jpg', title: 'الرجاء',intro:'', description: [], link: '/الرجاء'},
  { id : 7,src: 'img/rajaa/753.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261639-753.jpg', title: 'الرجاء',intro:'', description: [], link: '/الرجاء'}, 
  { id : 8,src: 'img/rajaa/777.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261737-777.jpg', title: 'الرجاء',intro:'', description: [], link: '/الرجاء'}, 
  { id : 9,src: 'img/rajaa/807.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261772-807.jpg', title: 'الرجاء',intro:'', description: [], link: '/الرجاء'},
  { id : 10,src: 'img/rajaa/741.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261664-741.jpg', title: 'الرجاء',intro:'', description: [], link: '/الرجاء'},

];

//douaa

export const AthkarSabeh = [
  { id : 1,src: 'img/athkarsabah/4.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261823-4.png', title:'أذكار الصباح',intro:'', description: ['محمد','رسول','عبد','صباح','خلق الله', 'ملائكة','حملة العرش'], link:'/أذكار-الصباح' },
  { id : 2,src: 'img/athkarsabah/5.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261825-5.png', title:'أذكار الصباح',intro:'', description: ['الملك للله','رب','سؤال','كسل','كبر','نار','قبر','عذاب','ملك','حمد','ملائكة','صباح'], link:'/أذكار-الصباح' },
  { id : 3,src: 'img/athkarsabah/7.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261870-7.png', title:'أذكار الصباح',intro:'', description: ['مصير','حياة','موت','مساء','صباح'], link:'/أذكار-الصباح' },
  { id : 4,src: 'img/athkarsabah/8.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261874-8.jpg', title :'أذكار الصباح',intro:'', description: ['ملائكة','خلق','محمد','رسول','عبد','حملة العرش','شهادة','صباح'],link:'/أذكار-الصباح' },
  { id : 5,src: 'img/athkarsabah/9.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261888-9.png', title:'أذكار الصباح',intro:'', description: ['دين','محمد','إبراهيم','مشرك','إخلاقي','فطرة','صباح'],link:'/أذكار-الصباح' },
];

export const AthkarMasaa = [
  { id : 1,src: 'img/athkarmassa/17.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261924-17.png', title: 'أذكارالمساء',intro:'', description: ['صباح','الله', 'مساء','موت','مصير'],link:'/أذكار-المساء' },
  { id : 2,src: 'img/athkarmassa/16.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721302027-16.png', title: 'أذكار المساء',intro:'', description: ['مساء','فطرة','مشرك','محمد','إخلاص','إبراهيم'],link:'/أذكار-المساء' },
  { id : 3,src: 'img/athkarmassa/10.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261910-10.png', title: 'أذكار المساء',intro:'', description: ['الله','عليم','سميع','سماء','الأرض'],link:'/أذكار-المساء' },
];

export const AthkarJoumouaa = [
  { id : 1,src: 'img/joumouaa/19.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262002-19.jpg',title: 'أذكار يوم الجمعة',intro:'', description: ['حمعة','جنة', 'عطر'],link:'/أذكار-يوم-الجمعة' },
  { id : 2,src: 'img/joumouaa/8.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261981-8.jpg', title: 'أذكار يوم الجمعة',intro:'', description: ['صالح الأعمال'],link:'/أذكار-يوم-الجمعة' },
  { id : 3,src: 'img/joumouaa/2.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261981-2.jpg', title:'أذكار يوم الجمعة',intro:'', description: ['جمعة','سورة الكهف'],link:'/أذكار-يوم-الجمعة' },
  { id : 4,src: 'img/joumouaa/1.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721261938-1.jpg', title  :'أذكار يوم الجمعة',intro:'', description: ['جمعة','مباركة'],link:'/أذكار-يوم-الجمعة' },
  { id : 5,src: 'img/joumouaa/24.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262032-24.jpg', title: 'أذكار يوم الجمعة',intro:'', description: ['نور','جمعة','عصر','دعوة','رزق','جنة','مباركة'],link:'/أذكار-يوم-الجمعة' },
  { id : 6,src: 'img/joumouaa/20.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262002-20.jpg', title: 'أذكار يوم الجمعة',intro:'', description: [''],link:'/أذكار-يوم-الجمعة' },

];

export const SalatAlaNabi = [
  { id : 1,src: 'img/salatnabi/199.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262048-199.jpg', title: 'الصلاة على النبي',intro:'', description: ['الصلاة على محمد','مغفرة', 'هم'],link:'/صلاة-على-النبي' },
  { id : 2,src: 'img/salatnabi/33.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262045-33.jpg', title: 'الصلاة على النبي',intro:'', description: ['الصلاة على محمد','آل محمد'],link:'/صلاة-على-النبي' },
];


export const DOUAAFAJR = [
  { id : 1,src: 'img/salatfajr/15.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262380-15.png',title: 'دعاء الفجر',intro:'', description: ['شر','إستغاثة','خير','الليلة','سلطان','رب','حمد'],link:'/صلاة-الفجر' },
  { id : 2,src: 'img/salatfajr/14.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262380-14.png',title: 'دعاء الفجر',intro:'', description: ['كفر','عذاب','قبر','فقر','إشتغاثة','بصر','بدن','سمع','اللهم'],link:'/صلاة-الفجر' },
  { id : 3,src: 'img/salatfajr/13.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262405-13.png',title: 'دعاء الفجر',intro:'', description: ['حمد','شكر','صباح','رسول','محمد','رب','رضا','إسلام'],link:'/صلاة-الفجر' },
  { id : 4,src: 'img/salatfajr/10.webp',URL:'https://image.noelshack.com/fichiers/2024/29/4/1721262393-10.png',title: 'دعاء الفجر',intro:'', description: ['عليم','سماء سميع','أرض','إسم الله','ضرر'],link:'/صلاة-الفجر' },
  ];




  
  







import React, { useState, useEffect } from "react";
import { CiBookmarkPlus } from "react-icons/ci";
import { IoCloseSharp, IoLinkSharp } from "react-icons/io5";
import {
  akwal2,
  akwal3,
  akwal5,
  akwal7,
  salat,
  sawm,
  zakat,
  tawhid,
  ikhlas,
  khichya,
  rajaa,
  AthkarSabeh,
  AthkarMasaa,
  AthkarJoumouaa,
  SalatAlaNabi,
  DOUAAFAJR,
} from "../../dataLibrary"; // Import des éléments du portfolio
import { Link } from "react-router-dom";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import Suggestion from "../Suggestions/suggestion";
import "./portfolio.css";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../categorie/categorie.css";

const CircleButton = ({ id, text, onClick }) => (
  <button
    className="circle-button-container1"
    onClick={() => onClick(text)}
    key={id}
  >
    <div className="circle-button1">{text}</div>
  </button>
);

const SearchResultsModal = ({
  isOpen,
  onClose,
  filteredImages,
  searchTerm,
}) => {
  

  if (!isOpen) return null;

  return (
    <div 
      id="search-modal-id" 
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={onClose}>
              <IoCloseSharp />
            </button>
            <label htmlFor="search-input">{searchTerm}</label>
          </div>
          <div className="modal-body">
            <div className="image-grid">
              {filteredImages.map((image) => (
                <a href={image.link} key={image.id} className="image-item">
                  <img src={image.src} alt={image.title} />
                  <p>{image.title}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Portfolio = ({ data, currentPage, setCurrentPage, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isModalOpen]);

  const [modalContent, setModalContent] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCircleButtonClick = (word) => {
    setSearchTerm(word);
    setIsSearchModalOpen(true);
  };

  const handleSearchButtonClick = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const allImages = [
    ...akwal2,
    ...akwal3,
    ...akwal5,
    ...akwal7,
    ...DOUAAFAJR,
    ...salat,
    ...sawm,
    ...zakat,
    ...tawhid,
    ...ikhlas,
    ...AthkarJoumouaa,
    ...AthkarMasaa,
    ...AthkarSabeh,
    ...SalatAlaNabi,
    ...khichya,
    ...rajaa,
  ];

  const filteredImages = allImages.filter((image) =>
    image.description.some((desc) =>
      desc.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const [collections, setCollections] = useState([
    {
      id: 1,
      name: "المجموعة الاولي",
      images: [],
    },
  ]);

  const handleDownload = (e) => {
    e.preventDefault();

    // Vérifiez que le contenu du modal et l'URL de l'image existent
    if (modalContent && modalContent.src) {
      // Créez un élément <a> temporaire pour télécharger l'image
      const link = document.createElement("a");
      link.href = modalContent.src;
      link.download = `${modalContent.title}.jpg`; // Nom du fichier basé sur modalContent.title
      // Ajoutez l'élément au document, cliquez dessus et ensuite le supprimez
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Image URL is not available.");
    }
  };

  const handleLikeToggle = (index) => {
    const updatedPortfolio = [...data];
    updatedPortfolio[index].liked = !updatedPortfolio[index].liked;
    setPortfolio(updatedPortfolio);
  };

  const addImageToCollection = (collectionId, imageUrl) => {
    setCollections((prevCollections) => {
      return prevCollections.map((collection) => {
        if (collection.id === collectionId) {
          return {
            ...collection,
            images: [...collection.images, imageUrl],
          };
        }
        return collection;
      });
    });
  };

  const [newCollectionName, setNewCollectionName] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleCreateCollection = () => {
    if (!newCollectionName.trim()) {
      alert("الرجاء إدخال اسم المجموعة");
      return;
    }

    const newCollection = {
      id: collections.length + 1,
      name: newCollectionName,
      images: [],
    };

    setCollections([...collections, newCollection]);
    setNewCollectionName("");
    setShowSuccessMessage(true);

    // Masquer le message de succès après quelques secondes
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  };

  

  const [portfolio, setPortfolio] = useState(data);

  const handleImageClick = (index) => {
    setModalContent(data[index]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //pagination
  const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const Pagination = ({ itemsPerPage, totalItems, currentPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`page-item ${currentPage === number ? "active" : ""}`}
            >
              <a
                onClick={() => paginate(number)}
                href="#portfolio"
                className="page-link"
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <div>
      <div id="portfolio">
        <div className="container-fluid gal-container">
          <div className="row-image">
            {currentItems.map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6 co-xs-12 port-item">
                <div className="box7">
                  <img src={item.src} alt={item.title} />
                  <div className="box-content">
                    <ul className="icon">
                      <li>
                        <a
                          href="#"
                          className="fa fa-eye"
                          data-toggle="modal"
                          data-target="#modal-id"
                          onClick={() =>
                            handleImageClick(indexOfFirstItem + index)
                          }
                        ></a>
                      </li>
                      <li>
                        <a
                          className="fa fa-share-alt"
                          data-toggle="modal"
                          data-target="#icon-id"
                          onClick={() =>
                            handleImageClick(indexOfFirstItem + index)
                          }
                        ></a>
                      </li>
                      <li>
                        <a
                          className={
                            item.liked
                              ? "fa fa-heart red-heart"
                              : "fa fa-heart-o"
                          }
                          onClick={() =>
                            handleLikeToggle(indexOfFirstItem + index)
                          }
                        ></a>
                        <span>{item.likeCount}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>

      <div id="modal-id" className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="bookmark"
                data-toggle="modal"
                data-target="#collection-id"
              >
                <CiBookmarkPlus />
              </button>
              <button type="button" className="close" data-dismiss="modal">
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body">
              {modalContent && (
                <>
                  <img src={modalContent.src} alt={modalContent.title} />
                  <div className="box-content">
                    <h3 className="title">{modalContent.title}</h3>
                    <span className="post">{modalContent.intro}</span>
                  </div>
                  <div className="col-12" style={{ direction: "rtl" }}>
                    <h2 className="titlekeyword">كلمات مفاتيح</h2>

                    <div className="button-container1">
                      {modalContent.description.length > 0 ? (
                        modalContent.description.map((word, index) => (
                          <CircleButton
                            key={index}
                            id={index}
                            text={word}
                            onClick={() => handleCircleButtonClick(word)}
                          />
                        ))
                      ) : (
                        <p className="no-keywords">لا توجد كلمات مفاتيح</p>
                      )}
                    </div>

                    <h2 className="titlekeyword">أنظر أيضا</h2>
                    <a 
                      href="https://shamela.ws/"
                      className="maktba"
                      target="_blank"
                    >
                      <IoLinkSharp  className="maktba-icon"/>
                      المكتبة الشاملة
                    </a>
                    <div
                      className="download-content"
                      style={{ direction: "ltr"}}
                    >
                      <form >
                        <ul >
                          <li >
                            <button
                              type="button"
                              onClick={handleDownload}
                              data-dismiss="modal"
                            >
                              {" "}
                              <i className="fa fa-download"></i>تحميل
                            </button>
                            <button type="button" data-dismiss="modal">
                              <i className="fa fa-eye"></i>إرجع
                            </button>
                          </li>
                        </ul>
                      </form>
                    </div>

                    <SearchResultsModal
                      isOpen={isSearchModalOpen}
                      onClose={closeSearchModal}
                      filteredImages={filteredImages}
                      searchTerm={searchTerm}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="icon-id" className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <buttons type="button" className="close" data-dismiss="modal">
                <IoCloseSharp />
              </buttons>
            </div>
            <div className="modal-body">
              {modalContent && (
                <div className="share-icons">
                  <h3 className="SHAREtitre">
                    شارك على مواقع التواصل الاجتماعي
                  </h3>
                  <div className="iconsshare">
                    <FacebookShareButton
                      url={modalContent.URL}
                      hashtag={`${modalContent.title }# جواهر القلوب#` }

                    >
                      <FacebookIcon size={52} round />
                    </FacebookShareButton>
                    <EmailShareButton
                      url={modalContent.URL}
                      subject="ألقي نضرة على موقعنا جواهر القلوب"
                    >
                      <EmailIcon
                        size={52}
                        round
                        iconFillColor="#FFFFFF"
                        bgStyle={{ fill: "#ba3509" }}
                      />
                    </EmailShareButton>
                    <LinkedinShareButton
                      url={modalContent.URL}
                      hashtag={`${modalContent.title }# جواهر القلوب#` }
                    >
                      <LinkedinIcon size={52} round />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url={modalContent.URL}
                      title="ألقي نضرة على موقعنا جواهر القلوب"
                      separator=":"
                    >
                      <WhatsappIcon size={52} round />
                    </WhatsappShareButton>
                    <FacebookMessengerShareButton
                      url={modalContent.URL}
                      appId="1015825056149511"
                      to=""
                    >
                      <FacebookMessengerIcon size={52} round />
                    </FacebookMessengerShareButton>
                    <TwitterShareButton
                      url={modalContent.URL}
                      title="ألقي نضرة على موقعنا جواهر القلوب"
                      hashtag={`${modalContent.title }# جواهر القلوب#` }

                    >
                      <TwitterIcon size={52} round />
                    </TwitterShareButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="collection-id"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <buttons type="button" className="close" data-dismiss="modal">
                <IoCloseSharp />
              </buttons>
            </div>

            <div className="modal-body" style={{ direction: "rtl" }}>
              {modalContent && (
                <div>
                  <h3 className="title">إضافة إلى المجموعة</h3>
                  <button
                    className="title"
                    data-toggle="modal"
                    data-target="#form"
                  >
                    إنشاء مجموعة جديدة
                  </button>

                  {collections.map((collection) => (
                    <div
                      className="collection-item"
                      onClick={() =>
                        addImageToCollection(collection.id, modalContent.src)
                      }
                    >
                      <img src={collection.images[0]} />

                      <div className="collection-info">
                        <h2 className="collection-name ">{collection.name}</h2>
                        <p className="collection-count">
                          {collection.images.length} صور
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="form" className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog" style={{ direction: "rtl" }}>
          <div className="modal-content">
            <div className="modal-content2">
              <div
                className="image-section"
                style={{
                  backgroundImage: ` url('${
                    modalContent ? modalContent.src : ""
                  }')`,
                }}
              ></div>
              <div className="form-section">
                <h3>إنشاء مجموعة جديدة</h3>
                <form onSubmit={handleCreateCollection}>
                  <div>
                    <label> اسم المجموعة</label>
                    <input
                      className="form-input"
                      type="text"
                      value={newCollectionName}
                      onChange={(e) => setNewCollectionName(e.target.value)}
                      maxLength="60"
                      required
                    />
                  </div>
                  {showSuccessMessage && (
                    <div className="success-message">
                      تم إنشاء المجموعة بنجاح
                    </div>
                  )}
                  <div className="modal-button">
                    <button
                      type="button"
                      className="btn2"
                      onClick={handleCreateCollection}
                    >
                      إنشاء مجموعة
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      إرجع<i className="fa fa-chevron-left"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Suggestion />
    </div>
  );
};

export default Portfolio;
